import React from 'react';

const skills = [
  'Linux (I use arch BTW)',
  'Shell Scripting',
  'Git',
  'NGINX',
  'RabbitMQ',
  'AWS',
  'GCP',
  'MySQL',
  'MongoDB',
  'Reddis',
  'Docker',
  'PHP',
  'Laravel',
  'Livewire',
  'C#',
  'Selenium',
  'EFCore',
  'Blazor',
  'GoLang',
  'Gorm',
  'TypeScript',
  'React',
  'Vue',
  'SvelteKit',
  'Bootstrap',
  'Tailwind',
  'Bulma',
  'DaisyUI'
];

function App() {
  return (
    <div className="p-4 lg:p-24">
      <div className="m-auto space-y-12">
      <section>
          <p className='mb-2 font-bold'>
            About Me
          </p>
          <p className='mb-6'>
            I'm ash, a full stack engineer from the UK.
          </p>
      </section>
      <section>
          <p className='mb-2 font-bold'>
            Socials
          </p>
          <div className="flex space-x-2">
            <a href="https://github.com/ashdevelops">
              <img className="w-8" src="https://www.svgrepo.com/show/512317/github-142.svg" />
            </a>
            <a href="mailto:ash.smith.dev@gmail.com">
              <img className="w-9" src="https://www.svgrepo.com/show/511917/email-1572.svg" />
            </a>
          </div>
      </section>
      <section>
          <p className='mb-2 font-bold'>
            Projects
          </p>
          <a href="https://github.com/ashdevelops/php-case">php-case</a>
      </section>
      <section>
          <p className='mb-2 font-bold'>
            Technical Skills 
          </p>
          {skills.map(function(object, i){
              return <span>{object}, </span>
          })}
      </section>
      <section>
        <p className='mb-4'>This site was built with <a href="https://en.wikipedia.org/wiki/KISS_principle">KISS</a> in mind.</p>
      </section>
      </div>
  </div>
  );
}

export default App;
